@import (reference) "../../../styles/inputs.less";

@contentPadding: 20px;
@headerHeight: 35px;

.note-composer {
	width: 900px;

	&-header {
		align-items: center;
		background: @brandPrimary;
		display: flex;
		height: @headerHeight;
		justify-content: space-between;
		padding: 0 @contentPadding;

		&-close-button {
			margin-left: 15px;
		}
	}

	&-body {
		display: flex;
		height: ~"calc(100% - @{headerHeight})";
		
		&-left {
			border-right: 1px solid #E3E7E9;
			box-sizing: border-box;
			height: 100%;
			width: 52%;
		}

		&-right {
			flex-grow: 1;
			height: 100%;
			/* can't believe this is needed! */
			width: 48%;
		}
	}

	&-referenced-contacts-placeholder {
		display: flex;
		justify-content: center;
		margin-top: 25%;

		&-icon {
			margin: 0 auto;
			width: 54px;
		}

		&-content {
			color: @grayIconFill;
			display: inline-flex;
			flex-direction: column;
			font-size: 12px;
			line-height: 18px;
			text-align: center;
		}

		&-title {
			color: @brandSecondary;
			font-size: 16px;
			line-height: 22px;
			margin-top: 16px;
		}

		&-description {
			margin-bottom: 16px;
			margin-top: 10px;
		}

		&-add-button {
			align-self: center;
		}
	}

	&-editor {
		display: flex;
		flex-direction: column;
		height: 100%;

		&-header {
			border-bottom: 1px solid #E3E7E9;
			box-sizing: border-box;
			padding: 25px @contentPadding 15px @contentPadding;

			&-title {
				color: @titles;
				font-size: 18px;
				line-height: 22px;
			}

			&-options {
				align-items: center;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
			}
		}

		&-add-cc-button {
			font-size: 14px;
			line-height: 22px;
		}

		&-cc-field {
			padding: 0;
			margin-top: 10px;
			
			.cc-field-token-container {
				margin: 0;

				&-tokens {
					max-height: 100px;
					overflow: auto;
				}
			}
		}

		&-body {
			display: flex;
			flex-basis: 0;
			flex-grow: 1;
			min-height: 0;
			position: relative;
		}

		&-note-editor.note-editor {
			display: flex;
			flex-direction: column;
			height: 100%;
			max-height: initial;
			width: 100%;

			.note-editor-attachments {
				padding: 0 16px;
			}

			.note-editor-email-message-header {
				margin: 10px 20px 0 20px;
			}

			.note-editor-body {
				height: 100%;
			}

			.note-editor-footer {
				padding: @contentPadding;
			}
		}

		&-footer {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: @contentPadding;
		}
	}

	&-referenced-contacts {
		height: 100%;
	}
}

.note-composer-modal {
	.modal-overlay-content {
		padding: 0;
		box-sizing: content-box;

		.note-composer-body {
			height: 500px;
			max-height: 500px;
			min-height: 500px;

			@media only screen and (min-height: 768px) {
				height: 540px;
				max-height: 540px;
				min-height: 540px;
			}

			@media only screen and (min-height: 1024px) {
				height: 740px;
				max-height: 740px;
				min-height: 740px;
			}
		}
	}
}

.note-composer-editor-popover.Popover {
	z-index: 20 !important;
}