@import (reference) "../../styles/colors.less";

.company-container {
	box-sizing: border-box;
	display: block; // needed for splitview
	width: 100%;


	&-split-view.split-view {
		min-height: 100%;
		
		@media only screen and (max-width: 880px) {
			.split-view-master {
				width: 360px;
			}

			.split-view-divider {
				left: 360px;
			}
		}

		.split-view-master {
			box-sizing: border-box;
			padding: 50px 30px 30px 0;
			position: relative;
		}

		.split-view-detail {
			.tab-view {
				.scroll-view {
					overflow: unset;

					&-content {
						min-height: 500px;
					}
				}
			}
		}
	}
}