.deactivate-container {

	&-header {
		color: #D0021B;
	}
	&-content, &-feedback {
		font-size: 14px;
	}
	&-feedback {
		color: #979797;
	}
	&-buttons {
		&-deactivate.cta-button {
			background-color: #D0021B;
			
			&:hover {
				background: #FD0826;
			}
		}

		&-cancel {
			margin-left: 10px;
		}
	}
	& > *:not(:first-child) {
		padding-bottom: 20px;
	}
	& > *:first-child {
		padding-bottom: 10px;
	}
	& > *:last-child {
		padding-bottom: 0px;
	}
}