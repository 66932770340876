@import (reference) "../../../styles/colors.less";

@contentPadding: 30px;

.mention-list {
	display: flex;
	flex-direction: column;

	&-autocomplete-search-field {
		.dropdown {
			display: block;
		}

		&-anchor {
			flex-grow: 1;
		}

		&-dropdown-content {
			width: 100%;
		}

		&-icon.search-icon {
			height: 16px;
			margin-right: 8px;
			width: 16px;
		}
	}

	&-header {
		background: #fff;
		padding: @contentPadding @contentPadding 10px @contentPadding;

		&-title {
			color: #4A4A4A;
			font-size: 16px;
			line-height: 22px;
			margin-bottom: 15px;

			&-icon {
				height: 20px;
				width: 23px;
			}

			&-text {
				margin-left: 16px;
			}
		}
	}

	&-body {
		flex-grow: 1;
		overflow: auto;
		padding-bottom: 20px;
	}

	&-item {
		align-items: center;
		cursor: pointer;
		display: flex;
		justify-content: center;
		padding: 10px 0;

		&-content {
			align-items: center;
			display: flex;
			width: ~"calc(100% - 2*@{contentPadding})";
		}

		&-remove-button {
			// width is 14px, without margin
			margin-right: 10px;
		}

		&-avatar {
			width: 45px;
			height: 45px;
		}

		&-info {
			display: inline-flex;
			flex-direction: column;
			margin-left: 14px;
			width: ~"calc(100% - 83px)";

			&-name {
				color: @titles;
				font-size: 16px;
				letter-spacing: 0;
				line-height: 22px;
			}

			&-message {
				font-size: 13px;
				color: @brandPrimaryText;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}