.owner-tag-report {
	display: flex;
	align-items: center;
	flex-direction: column;

	&-loader {
		padding-top: 35px;
	}

	&-graphic {
		padding-bottom: 15px;
		padding-top: 100px;
	}

	&-download {
		text-decoration: none;
	}
}