@import "../../../../styles/colors.less";
@import "../../../../styles/inputs.less";

.opportunity-card {
	background: #FFFFFF;
	border: 1px solid #F2F2F2;
	box-shadow: 0 0 6px 0 rgba(139,139,139,0.10);
	box-sizing: border-box;
	margin-top: 10px;
	padding: 15px 20px;
	position: relative;
	width: 100%;
	border-radius: 8px;

	&.opportunity-card-busy {
		opacity: 0.66;
	}

	&-more-menu {
		position: absolute;
		right: 5px;
		top: 5px;
	}

	&-title {
		color: @brandPrimary;
		cursor: pointer;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 22px;
		text-decoration: none;

		&:hover {
			color: @brandPrimaryHover;
			text-decoration: underline;
		}

		&-content {
			display: block;
		}
	}

	&-stage-indicator-container {
		align-items: center;
		display: flex;
		margin-top: 15px;
	}

	&-stage-indicator {
	}

	&-stage-indicator-name {
		color: #858585;
		font-size: 12px;
		line-height: 22px;
		margin-left: 10px;
	}

	&-field-label {
		color: #858585;
		font-size: 12px;
		line-height: 22px;
	}

	&-field-text {
		color: @titles;
		font-size: 12px;
	}

	&-amount {
		color: @success;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 22px;
		margin-top: 8px;
	}

	&-meta {
		margin-top: 8px;
	}
}