@import (reference) "../../../styles/colors.less";

.merge-companies-wizard {
	display: flex;
	flex-direction: column;
	min-height: 300px;
	position: relative;

	&-company {
		align-items: center;
		border-bottom: 1px solid #f0eded;
		cursor: pointer;
		display: flex;
		font-size: 14px;
		margin-top: 20px 0;
		padding: 10px 0;

		&-radio-button {
			pointer-events: none;
		}

		.company-name-with-email-domain {
			display: inline-block;
			margin-left: 10px;
			margin-bottom: 2px;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		max-width: 400px;

		&-header {
			color: @brandSecondary;
			font-size: 18px;
			text-align: center;
			margin-bottom: 10px;
		}

		&-subheader {
			font-size: 14px;
			text-align: center;
			margin-bottom: 20px;
		}

		&-list {
			max-height: 400px;
			overflow-y: auto;
			flex-grow: 1;
		}

		&-footer {
			margin-top: 30px;

			& > *:not(:first-child) {
				margin-left: 10px;
			}
		}
	}
}