@import (reference) "../../styles/colors.less";

.fab-callout-placeholder {
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	&-content {
		align-items: center;
		background: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 15%;
		padding-bottom: 40px;
		z-index: 1;
		width: 100%;

		&-icon {
			height: 180px;
			width: 180px;
		}

		&-title {
			color: @brandSecondary;
			font-size: 18px;
			line-height: 22px;
			margin-top: 30px;
		}

		&-subtitle {
			color: @titles;
			font-size: 14px;
			line-height: 16px;
			margin-top: 10px;
			text-align: center;
		}
	}
	
	&-fab-pointer {
		bottom: 60px;
		position: fixed;
		right: 120px;

		@media only screen and (max-width: 1000px) {
			height: 180px;
			width: 180px;
		}
	}
}