@import (reference) "../../../styles/colors.less";

.kit-email-composer {
	&-footer {
		box-sizing: border-box;
		display: flex;
		padding-top: 30px;

		& > *:not(:first-child) {
			margin-left: 10px;
		}

		&-send-button {
		}

		&-cancel-button {
		}
	}
}