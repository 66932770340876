@import (reference) "../MainContainer/styles.less";
@import (reference) "../../styles/colors.less";

.people-container { 
	background-color: white;
	height: 100%;
	width: 100%;

	&-nav-item {
		&-total {
			color: #858585;
		}
	}

	&-contacts {
		box-sizing: border-box;
		height: 100%;
	}

	.people-container-list {
		height: 100%;
		box-sizing: border-box;

		.scroll-view-header-content {
			padding-top: 30px;
			background: #fff;
		}
	}

	&-loading {
		display: 'flex';
		justify-content: 'center';
		margin-top: 30px;
	}
}


/* CONTENT FROM THE OLD CONTACTINFO COMPONENT */

.standardTextboxMed, .standardTextboxShort, .standardTextboxVeryShort, .standardTextboxLong, .standardTextboxLongResponsive,
.standardTextboxVeryLong, .standardTextArea, .standardTextAreaShort, .contactStateProvinceTextbox, .companyNameTextbox { 
	width:205px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius:3px;
	border:1px solid @inputBorderColor;
	background-color: #f9f9f9;
	color: #4a4a4a;
	padding:5px 5px 5px 10px;
	font-size:14px;
}

.standardTextboxMed:focus, .standardTextboxShort:focus, .standardTextboxVeryShort:focus, .standardTextboxLong:focus, .standardTextboxLongResponsive:focus,
.standardTextboxVeryLong:focus, .standardTextArea:focus, .standardTextAreaShort:focus, .contactStateProvinceTextbox:focus, 
.companyNameTextbox:focus, .standardDropdown:focus, .standardDropdownShort:focus
{
	outline: none !important;
}

.standardTextboxVeryLong {
	width: 400px;
}

.standardTextArea {
	width: 100%;
	height: 200px;
	outline: none;
}

.standardTextAreaShort {
	width: 100%;
	height: 45px;
	outline: none;
}

.companyNameTextbox {
	width: 100%;
	font-size: 24px;
}

.standardTextboxShort { 
	width:135px;
}

.standardTextboxVeryShort { 
	width:70px;
}

.standardTextboxLong { 
	width:300px;
}

.standardTextboxLongResponsive { 
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
	width: 90%;
	max-width:300px;
}

.standardDropdown, .standardDropdownShort, .standardDropdownVeryShort { 
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius:3px;
	border:1px solid @inputBorderColor !important;
	background-color: #f9f9f9;
	color: #4a4a4a;
	padding:5px 5px 5px 10px !important;
	height: auto !important;
	font-size:14px;
}

.standardDropdown:focus, .standardDropdownShort:focus, .standardDropdownVeryShort:focus {
	outline: none;
}

.standardDropdown {
	width:220px !important;
}

.standardDropdownShort {
	width: 180px !important;
}

.standardDropdownVeryShort {
	width: 110px !important;
}

.contactSaveButton, .contactCancelButton, .standardSaveButton, .standardCancelButton {
	display: inline-block;
	background: @brandPrimary;
	font-size:14px;
	padding: 5px 20px 5px 20px;
	text-align: center;
	color: white;
	cursor:pointer;
	border-radius:3px;
	border: 0;
	box-shadow: 0 1px 2px 0px #cccccc;
  }

.contactCancelButton, .standardCancelButton { 
	background: white;
	color: @brandPrimary;
	border: 1px solid @brandPrimary;
	margin-left: 10px;
}

.keyFactsContainer, .tagsContainer {
	margin-bottom: 5px;
}

.contactInfoFieldContainerStandard label { 
	display: inline-block;
	font-size: 14px;
	text-align: left;
	margin-right: 10px;
	width: 100px;
}

.contactInfoFieldContainerStandard { 
	margin-bottom: 20px;
}

.contactInfoFieldButtonContainer { 
	margin-top: 30px;
	margin-bottom: 10px;
}

.formattedLink {
	color: @brandPrimary;
	text-decoration: none;
}

.formattedLink:hover {
	color: @brandPrimaryHover;
	text-decoration: underline;
}

.contactInfoFieldContainerStandard .fieldLabel { 
	font-size: 12px;
	color: #a7abad;
}

.contactInfoFieldContainerStandard .formFieldLabel { 
	font-size: 13px;
	color: #a7abad;
	margin-bottom: 5px;
}

.contactInfoFieldContainerStandard .fieldValue { 
	font-size: 14px;
	color: #0d0f11;
}