.edit-opportunity-search-field {
	&-clear-button {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	&-left-accessory {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-right: 10px;
	}

	.dropdown-content {
		max-width: 100%;
		width: 100%;
	}
}