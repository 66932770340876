@import (reference) "../../../styles/colors.less";

@iconSize: 60px;
@horizontalPadding: 20px;
@disclosureButtonSize: 30px;

.settings-group {
	background: #FFFFFF;
	border-radius: 3px;
	border: 1px solid #D3DBDE;
	box-shadow: 0 0 6px 0 rgba(139,139,139,0.20);
	overflow: hidden;
	padding-left: @horizontalPadding;
	padding-right: @horizontalPadding;

	&.settings-group-expanded {
		.settings-group-header {
			border-bottom: 1px solid #E3E7E9;

			&-disclosure {
				transform: rotateZ(-180deg);
				transition: all ease-in 0.25s;
			}
		}
	}

	&-header {
		align-items: center;
		border-bottom: none;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		padding: 30px 0;

		&-icon {
			align-items: center;
			display: flex;
			height: @iconSize;
			justify-content: center;
			margin-right: @horizontalPadding;
			width: @iconSize;
		}

		&-content {
			width: ~"calc(100% - 2*@{horizontalPadding} - @{iconSize} - @{disclosureButtonSize})";
		}

		&-disclosure {
			align-items: center;
			display: flex;
			height: @disclosureButtonSize;
			justify-content: center;
			margin-left: @horizontalPadding;
			transition: all ease-in 0.25s;
			width: @disclosureButtonSize;

			img {
				height: 10px;
				width: 17px;
			}
		}
	}

	&-name {
		font-size: 18px;
		color: @titles;
		line-height: 22px;
	}

	&-description {
		color: #8E8E8E;
		font-size: 14px;
		letter-spacing: 0;
		margin-top: 8px;
	}

	&-body {
		&-content {
			padding: 15px @horizontalPadding;
		}
	}

	&-footer {
		margin-top: 30px;

		& > *:not(:first-child) {
			margin-left: 10px;
		}
	}

	&-radio-button-group {
		color: @titles;
		font-size: 14px;
		line-height: 22px;

		&:not(:first-of-type) {
			margin-top: 30px;
		}

		&-header {
			color: #858585;
			margin-bottom: 12px;
		}

		& > .radio-button,
		& > input[type='radio'] {
			&:not(:first-of-type) {
				margin-top: 10px;
			}
		}
	}
}
