@import (reference) "../../styles/colors.less";

@headerHeight: 96px; // see AppBarHeight
@contentPadding: 40px;
@contentPaddingCompact: 20px;
@darkBackgroundColor: #f9f9f9;
@navWidth: 250px;
@navWidthCompact: 72px;

.main-container {
    background-color: white;
    display: flex;
    height: 100%;
    min-width: 768px;

    &.main-container-dark-background {
        background-color: @darkBackgroundColor;

        .main-container-header {
            background-color: @darkBackgroundColor;
            z-Index: 2,
        }
    }

    &.main-container-compact-nav {
        .main-container-header {
            padding: 0 @contentPaddingCompact;
            z-Index: 2,
        }
        
        .main-container-nav {
            min-width: @navWidthCompact;
            width: @navWidthCompact;
        }

        .main-container-right-container {
            min-width: ~"calc(100% - @{navWidthCompact})";
            width: ~"calc(100% - @{navWidthCompact})";
        }

        .main-container-child {
            padding-left: @contentPaddingCompact;
            padding-right: @contentPaddingCompact;
        }
    }

    &-header {
        padding: 0 @contentPadding;
    }

    &-nav {
        width: @navWidth;
        z-index: 2;
    }

    &-right-container {
        min-width: ~"calc(100% - @{navWidth})";
        position: relative;
        width: ~"calc(100% - @{navWidth})";

        &-content {
            display: flex;
            height: ~"calc(100% - @{headerHeight})";
            min-height: ~"calc(100% - @{headerHeight})";
            overflow: auto;

            &.main-container-right-container-content--fixed-height {
                height: 100%;
                min-height: 100%;
            }

            & > * {
                flex-grow: 1;
            }
        }
    }

    &-child {
        padding-left: @contentPadding;
        padding-right: @contentPadding;
    }
}

.zIndexContainerDoNotRemove {
    position: relative;
    z-index: 5;
}

.zIndexContainerDoNotRemove2 {
    position: relative;
    z-index: 6;
}
