@import (reference) "../../../styles/colors.less";
@import (reference) "../../../styles/inputs.less";

.kanban-delete-column-confirmation {
	width: 358px;

	&-spinner {
	}
	
	&-header {
		text-align: right;
	}

	&-title {
		color: @brandPrimary;
		font-size: 18px;
		line-height: 22px;
		margin-top: 25px;
		text-align: center;
		margin-bottom: 10px;
	}

	&-body {
		&-info {
			display: flex;
			flex-direction: column;
			justify-content: center;

			&-icon.warning-icon {
				align-self: center;
				height: 90px;
				width: auto;
			}
		}
	}

	&-column-selector {
		margin-top: 15px;

		.dropdown-anchor {
			.text-field();
			flex-grow: 1;

			.select-box-trigger {
				flex-grow: 1;
			}
		}

		.dropdown-content {
			width: 100%;
		}
	}

	&-footer {
		margin-top: 20px;
	}
}