@import '../fonts/fonts.less';
@import './colors.less';
@import './inputs.less';
@import '../containers/People/styles.less';

div[data-radix-popper-content-wrapper] {
	z-index: 5 !important;
}

body,
html {
	margin: 0;
	font-family: 'Sora', sans-serif;
	height: 100%;
	/*overflow: hidden; DO NOT ADD THIS BACK*/
	text-align: left;
	background-color: #f9f9f9;
}

#react-root {
	height: 100%;
	display: block;
}

.disabled,
*:disabled {
	opacity: 0.5;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

figure {
	padding: 0;
	margin: 0;
}

.card-welcome {
	color: @brandPrimary;
	display: flex;
	align-items: center;

	.user-avatar {
		margin-right: 10px;
		width: 35px;
		height: 35px;
	}
}

.truncate-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.required {
	color: @urgentDate;
}

.absolute-center {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.hide {
	display: none;
}

.lev-loading-spinner {
	border: 8px solid #f9f9f9;
	border-top: 8px solid @brandPrimary;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	margin: 50px auto 0px auto;
	animation: spin 1s linear infinite;
}

.loaderContainer {
	text-align: center;
}

.loaderSmall {
	display: inline-block;
	border: 4px solid #f9f9f9;
	border-top: 4px solid @brandPrimary;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	margin: 30px auto 0px auto;
	animation: spin 1s linear infinite;
}

.loader20pxNoTopMargin {
	display: inline-block;
	border: 4px solid #f9f9f9;
	border-top: 4px solid @brandPrimary;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	margin: 0px auto 0px auto;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

menu {
	padding: 0;
	margin: 0;
}
